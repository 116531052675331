import React from 'react';
import './App.css'; // We'll define the styling here

const WelcomePage = () => {
  return (
    <div className="welcome-container">
      <img src="/mbBackground.jpg" alt="Coming Soon" className="full-width-image" />
      <div className="overlay-text">
        <h1>Welcome to Madame Burlesque!</h1>
        <p>Coming Soon</p>
      </div>
    </div>
  );
};

export default WelcomePage;
